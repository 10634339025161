<template>
  <section class="cate-orange">
    <h2>メール配信設定</h2>

    <p>メールを受信したいものにチェックを入れて「変更する」ボタンを押してください。<br>
      変更後、反映されるまでしばらくメールが配信される可能性がございます。ご了承くださいませ。</p>

    <div class="setting">
      <ul>
        <li v-if="isShow">
          <input v-model="form.is_survey_email"
                 type="checkbox"
                 id="is_survey_email"
                 :true-value="1"
                 :false-value="0"/>

          <label for="is_survey_email"
                 v-text="'アンケートメールを受信する'"/>
        </li>
        <li v-if="isShow">
          <input v-model="form.is_transaction_email"
                 type="checkbox"
                 id="is_transaction_email"
                 :true-value="1"
                 :false-value="0"/>

          <label for="is_transaction_email"
                 v-text="'ポイント承認・否認時にメールを受信する'"/>
        </li>
        <li class="has-text-desc">
          <div class="input-group">
            <input v-model="form.is_notification_email"
                 type="checkbox"
                 id="is_notification_email"
                 />
            <label v-text="'お知らせメール（必須)'"/>
          </div>
          <p class="text-desc-checkbox">キャンペーンのご案内やサイト利用に関する重要なおしらせをご案内します。</p>
        </li>
        <li class="has-text-desc">
          <div class="input-group">
            <input v-model="form.is_magazine_email"
                   type="checkbox"
                   id="is_magazine_email"
                   :true-value="1"
                   :false-value="0"/>
            <label for="is_magazine_email"
                   v-text="'ChibiJobニュース'"/>
          </div>
          <p class="text-desc-checkbox">新着広告やおすすめの広告をご案内します。</p>
        </li>
      </ul>
    </div>

    <button @click.prevent="handleSettingEmail"
            class="btn-setting"
            v-text="'変更する'"/>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Common from '@/mixins/common.mixin'

export default {
  name: 'MailSetting',

  mixins: [Common],

  data () {
    return {
      form: {
        is_survey_email: 0,
        is_transaction_email: 0,
        is_magazine_email: 0,
        is_notification_email: 1
      },
      isShow: false
    }
  },

  created () {
    if (this.profile) {
      this.form.is_survey_email = +this.profile.is_survey_email
      this.form.is_transaction_email = +this.profile.is_transaction_email
      this.form.is_magazine_email = +this.profile.is_magazine_email
      this.form.is_notification_email = +this.profile.is_notification_email
    }
  },

  computed: {
    ...mapState('auth', ['profile'])
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },

  methods: {
    ...mapActions('user', ['userSettingEmail']),
    ...mapActions('auth', ['userProfile']),

    handleSettingEmail () {
      this.userSettingEmail(this.form).then(result => {
        if (result.status === 200) {
          this.userProfile()
          this.openPageNative('Profile.Mypage')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-family: $font-family-title;
  @include font-size(36px);
  text-align: center;
  color: #C66C44;
  font-weight: normal;
  margin-bottom: 120px;
  line-height: 1;
  @media #{$info-phone} {
    font-size: 32px;
    margin-bottom: 94px;
  }
}
p {
  @include font-size(18px);
  margin-bottom: 50px;
  @media #{$info-phone} {
    font-size: 18px;
  }
}
.setting {
  background: #E7E5DE;
  padding: 44px;
  margin-bottom: 60px;
  @include border-radius(6px);
  @media #{$info-phone} {
    padding: 33px 23px;
    margin-bottom: 40px;
  }
  ul {
    li {
      @include font-size(18px);
      font-weight: 500;
      user-select: none;
      display: flex;
      align-items: center;
      @media #{$info-phone} {
        font-size: 18px;
        display: flex;
        align-items: flex-start;
      }
      input {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        margin-bottom: 3px;
        @media #{$info-phone} {
          margin-top: 5px;
        }
        accent-color: #0075ff;
      }
      label {
        margin-left: 15px;
      }
      .input-group {
        display: flex;
        align-items: center;
      }
      &.has-text-desc {
        display: block;
        .text-desc-checkbox {
          margin: 0 0 0 32px;
          font-size: 13px;
          padding-top: 5px;
        }
      }
    }
    li:not(:last-child) {
      margin-bottom: 20px;
    }
    #is_notification_email {
      pointer-events: none;
    }
  }
}
.btn-setting {
  background-color: #C66C44;
  border-radius: 6px;
  max-width: 357px;
  width: 100%;
  height: 51px;
  display: block;
  margin: 0 auto 52px;
  cursor: pointer;
  color: #FFFFFF;
  @include font-size(18px);
  @media #{$info-phone} {
    margin: 0 auto 25px;
  }
}
</style>
